<template>
  <div class="platform" ref="platform">
    <el-image
      class="bg-style"
      src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/platform_bg_2x.jpg"
      fit="none"
    >
    </el-image>
    <div class="header-box">
      <el-image
        class="title-bg"
        src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/title_bg_1x.png"
        fit="cover"
      >
      </el-image>
      <div class="title">华南（中山）花木产业交易服务平台大数据中心</div>
      <div class="back-to-home" @click="backToHome">返回首页</div>
    </div>
    <div class="data-main">
      <div class="box left" :class="showModalData ? 'filter' : ''">
        <div class="title">地被库存及价格指数</div>
        <div
          class="seedling-box"
          v-for="(item, index) in leftDataFromApi"
          :key="index"
        >
          <div class="h">
            <div class="class-name">
              <el-avatar class="i" :src="item.img"></el-avatar>
              <div>{{ item.typeName }}</div>
            </div>
            <div class="sm-font">品类(种){{ item.classAmount }}</div>
            <div class="sm-font">库存数量(株)</div>
            <div class="amount">{{ item.stock }}</div>
          </div>
          <div class="product-list">
            <el-row>
              <el-col :span="8"
                v-for="(goodsItem, goodsIndex) in item.goods"
                :key="goodsIndex"
                class="item"
                @click="handleProduct(goodsItem)"
              >
                <el-image
                  :src="goodsItem.picUrl"
                  fit="fit"
                  class="pic"
                />
                <div class="number">
                  NO.{{ goodsIndex + 1 }} {{ goodsItem.goodsName }}
                </div>
                <div class="sale">
                  <div class="spec">{{ goodsItem.specTxt }}</div>
                  <div class="inventory">库存：{{ goodsItem.inventory }}</div>
                  <div class="price-wrap">
                    <div class="label">售价：</div>
                    <div class="value">{{ goodsItem.priceTag }}</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="center">
        <SummaryData :filterBox="showModalData"></SummaryData>
        <CenterMap :data="mapData" :filterBox="showModalData"></CenterMap>
      </div>
      <div class="right" :class="showModalData ? 'filter' : ''">
        <div class="box block1">
          <div class="title">实时成交数据</div>
          <div class="slide-box" ref="box">
            <div v-show="orderList && orderList.length" class="slide-list" ref="slideList">
              <div
                class="slide-card"
                ref="card"
                v-for="(item, index) in orderList"
                :key="index"
              >
                <div class="order">
                  <div>{{ item.buyerName }}于</div>
                  <div>{{ item.buyerTime }}</div>
                  <div>购买</div>
                  <div class="orange">{{ item.goodsName }}</div>
                  <div>{{ item.number }}{{ item.unit }}</div>
                </div>
              </div>
            </div>
            <div v-if="!orderList || !orderList.length" class="empty">
              暂无成交记录
            </div>
          </div>
        </div>
        <div class="box block2">
          <div class="title">
            交易平台订单统计
          </div>
          <div class="data-card">
            <div class="l">
              <el-avatar class="i" :src="bigDataIMG.i_order"></el-avatar>
              <div class="data-name">累计交易金额</div>
              <div class="data-unit">单位（万元）</div>
              <div class="data-value">
                {{ rightData.totalTransactionAmount }}
              </div>
            </div>
            <div class="r-card">
              <div class="item item1">
                <div class="name">待确认订单</div>
                <div class="val">{{ rightData.toBeConfirmedOrderCount }}</div>
              </div>
              <div class="item item2">
                <div class="name">待支付订单</div>
                <div class="val">{{ rightData.toBePayOrderCount }}</div>
              </div>
              <div class="item item3">
                <div class="name">待发货订单</div>
                <div class="val">{{ rightData.toBeShipOrderCount }}</div>
              </div>
              <div class="item item4">
                <div class="name">已收货订单</div>
                <div class="val">{{ rightData.receivedOrderCount }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box block3">
          <div class="title">智慧苗场实时画面</div>
          <div class="main">
            <div class="header">
              <div class="label">当前苗圃画面：</div>
              <div class="select">
                <el-select
                  v-model="videoListQuery.warehouseNumber"
                  size="small"
                  popper-class="custom-popper"
                  :popper-append-to-body="false"
                  @change="handleChangeVideoListQueryWarehouseNumber"
                >
                  <el-option
                    v-for="{ warehouseNumber, warehouseName } in warehouseList"
                    :key="warehouseNumber"
                    :label="warehouseName"
                    :value="warehouseNumber"
                  />
                </el-select>
              </div>
            </div>
            <div class="list">
              <el-row :gutter="20">
                <el-col
                  v-for="({ videoUrl, accessToken }, index) in videoList"
                  :key="videoUrl"
                  :span="8"
                  class="video"
                >
                  <EzUIKitJs
                    :index="index + 1"
                    :url="videoUrl"
                    :accessToken="accessToken"
                    :audio="false"
                  />
                  <div class="title">摄像{{ index + 1 }}</div>
                </el-col>
              </el-row>
            </div>
            <div v-if="videoListPages >= 1" class="pagination">
              <div class="pages">第{{ videoListQuery.page }}/{{ videoListPages }}页</div>
              <div
                v-show="videoListPages > 1 && videoListQuery.page !== 1"
                class="btn prev-btn"
                @click="getVideoListData(-1)"
              >
                上一页
              </div>
              <div
                v-show="videoListPages > 1 && videoListQuery.page !== videoListPages"
                class="btn next-btn"
                @click="getVideoListData(1)"
              >
                下一页
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalData
      ref="modal"
      :show="showModalData"
      :seedlingNumber="handleNumberVal"
      :info="productInfo"
      @closeModal="handleCloseModal"
    ></ModalData>
  </div>
</template>

<script>
import SummaryData from "./SummaryData.vue";
import CenterMap from "./CenterMap.vue";
import ModalData from "./ModalData.vue";
import EzUIKitJs from "@/components/EzUIKitJs.vue"
import imgSrc from "@/utils/bigDataIMG.js";
const bigDataIMG = imgSrc.data();
import { dotNumber, detectZoom } from "@/utils";

import RightLine from "./RightLine.vue";
import RightBar from "./RightBar.vue";
const rightLineData = [
  { value: 100000, name: "9月" },
  { value: 300000, name: "10月" },
  { value: 1000000, name: "11月" },
  { value: 2000000, name: "12月" },
  { value: 3000000, name: "1月" },
  { value: 5000000, name: "2月" },
];
const rightBarData = [
  { value: 4, name: "9月" },
  { value: 12, name: "10月" },
  { value: 40, name: "11月" },
  { value: 80, name: "12月" },
  { value: 120, name: "1月" },
  { value: 200, name: "2月" },
]
import {
  // findKindOfNumAndTotalInventoryByType,
  // findTopInventoryMostSeedlingByType,
  findSouthernChinaWarehouseNum, //地图
  showShopStatistics,
} from "@/api";
import {
  getLargeScreenInventoryList,
  getLargeScreenOrderList,
  getLargeScreenWarehouseList,
  getLargeScreenVideoList
} from '@/api/direct'

export default {
  name: "CloudPlatformIndex",
  components: {
    SummaryData,
    CenterMap,
    ModalData,
    RightLine,
    RightBar,
    EzUIKitJs
  },
  data() {
    return {
      bigDataIMG,
      leftDataFromApi: [],
      handleNumberVal: "",
      rightLineData,
      rightBarData,
      showModalData: false,
      productInfo: {},
      mapData: [],
      rightData: {},
      // 实时成交数据
      orderList: [],
      // 苗圃列表数据
      warehouseList: [],
      // 视频列表数据
      videoList: [],
      videoListTotal: 0,
      videoListPages: 0,
      // 视频列表请求参数
      videoListQuery: {
        warehouseNumber: undefined,
        page: 1
        // limit: 2
      },
      offsetVal: 0
    };
  },
  watch: {
    showModalData(val) {
      if (val) {
        // this.$refs.platform.style.transformOrigin = 'unset'
        this.$refs.platform.style.transform = 'none'
      } else {
        this.$refs.platform.style.transform = `scale(${detectZoom()})`
      }
    }
  },
  mounted() {
    this.$refs.platform.style.transformOrigin = '0 0'
    this.$refs.platform.style.transform = `scale(${detectZoom()})`
    document.body.style.backgroundColor = '#00142E'
    
    Promise.all([
      this.getInventoryListData(),
      this.getOrderListData(),
      this.getWarehouseListData().then(() => {
        this.getVideoListData()
      })
    ])

    findSouthernChinaWarehouseNum().then((res) => {
      this.mapData = res.data.map((item) => {
        return {
          name: item.cityName,
          value: item.warehouseNum,
        };
      });
      return showShopStatistics();
    })
    .then((res) => {
      this.rightData = res.data;
      this.rightData.totalTransactionAmount = dotNumber(res.data.totalTransactionAmount/10000)
    });
  },
  destroyed() {
    // this.$refs.platform.style.transformOrigin = 'unset'
    // this.$refs.platform.style.transform = 'none'
    document.body.style.backgroundColor = '#fff'
  },
  methods: {
    handleProduct(item) {
      this.handleNumberVal = item.seedlingNumber;
      this.productInfo = item;
      this.$nextTick(() => {
        this.$refs.modal.initPieData()
        this.showModalData = true;
      })
    },
    handleCloseModal() {
      this.showModalData = false;
    },
    backToHome() {
      // document.querySelector('html').style.transform = `scale(1)`
      // document.body.style.backgroundColor = '#fff'
      // this.$router.go(-1)
      this.$router.replace({ name: 'Home' })
    },

    /* 获取地被库存及价格指数列表数据 */
    getInventoryListData() {
      return new Promise((resolve, reject) => {
        getLargeScreenInventoryList().then((res) => {
          const {
            productCount = 0,
            inventoryCount = '',
            massifInventoryVos: list = []
          } = res.data
          this.leftDataFromApi.push({
            type: '26',
            typeName: '地被',
            img: bigDataIMG.i_lawn,
            classAmount: productCount,
            stock: inventoryCount,
            goods: list.map((item) => {
              const {
                height,
                heightEnd,
                crownWidth,
                crownWidthEnd,
                // unit,
                price = 5
              } = item
              return {
                ...item,
                specTxt: `高${height}-${heightEnd}cm 冠${crownWidth}-${crownWidthEnd}cm`,
                priceTag: `${price}元/株`
              }
            })
          })
          resolve()
        }).catch((error) => {
          reject()
          console.log('getInventoryListData', error)
        })
      })
    },

    /* 获取实时成交数据 */
    getOrderListData() {
      return new Promise((resolve, reject) => {
        getLargeScreenOrderList().then((res) => {
          const list = res.data
          this.orderList = list
          // 成交记录大于4条, 开启滚动
          list && list.length > 4 && this.scrollCard()
          resolve()
        }).catch((error) => {
          reject()
          console.log('getOrderListData', error)
        })
      })
    },

    /* 获取苗圃列表数据 */
    getWarehouseListData() {
      return new Promise((resolve, reject) => {
        getLargeScreenWarehouseList().then((res) => {
          const list = res.data
          this.warehouseList = list
          if(list && list.length) {
            this.videoListQuery.warehouseNumber = list[0].warehouseNumber
          }
          resolve()
        }).catch((error) => {
          reject()
          console.log('getWarehouseListData', error)
        })
      })
    },

    /* 获取苗圃视频列表数据 */
    getVideoListData(pagination = 0) {
      return new Promise((resolve, reject) => {
        // 分页参数不为空时, 需要先重置视频列表数据
        pagination !== 0 && this.resetVideoList(false)
        // 构造请求参数
        const { videoListQuery } = this
        this.videoListQuery.page += pagination
        getLargeScreenVideoList(videoListQuery).then((res) => {
          const { list = [], total = 0, pages = 0 } = res.data
          this.videoList = list
          this.videoListTotal = total
          this.videoListPages = pages
          resolve()
        }).catch((error) => {
          reject()
          console.log('getVideoListData', error)
        })
      })
    },

    /* 当前选择苗圃改变时的事件监听处理 */
    handleChangeVideoListQueryWarehouseNumber(warehouseNumber) {
      const { videoListQuery } = this
      this.videoListQuery = {
        ...videoListQuery,
        warehouseNumber,
        // 重置页码
        page: 1
      }
      // 重置视频列表数据
      this.resetVideoList(true)
      this.getVideoListData()
    },

    /* 重置视频列表数据 */
    resetVideoList(isAllClear = false) {
      // 重置视频列表数据
      this.videoList = []
      if(isAllClear) {
        this.videoListTotal = 0
        this.videoListPages = 0
      }
    },

    /* 实时成交数据的滚动处理 */
    scrollCard() {
      // let card = this.$refs.card
      const slideListRef = this.$refs.slideList;
      // let redundantLength = card.offsetWidth * (this.CompanyDataArr.length - this.CompanyDataReal.length)
      // console.log(slideListRef.offsetWidth)
      // let redundantLength = slideListRef.offsetWidth / 2
      if (Math.abs(this.offsetVal) >= slideListRef.offsetHeight / 2) {
        this.offsetVal = 0;
        slideListRef.style.top = `0px`;
      } else {
        this.offsetVal = this.offsetVal - 1;
        slideListRef.style.top = `${this.offsetVal}px`;
      }
      setTimeout(this.scrollCard, 125);
    },
  }
};
</script>

<style lang="less" scoped>
.platform {
  height: 100%;
  user-select: none;
  // overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  .bg-style {
    width: 1920px;
    height: 1080px;
    position: absolute;
    top: 0;
    // right: 0;
    // bottom: 0;
    left: 0;
  }
  .header-box {
    width: 1900px;
    height: 232px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    .title-bg {
      width: 100%;
      height: auto;
      z-index: 1;
    }
    .title {
      font-size: 36px;
      font-family: PangMenZhengDao;
      color: #60d6ff;
      letter-spacing: 2px;
      //   text-shadow: 0px 4px 8px #001183;
      background: -webkit-linear-gradient(180deg, #60d6ff 0%, #5369ff 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      position: absolute;
      top: 44px;
      width: 100%;
      text-align: center;
    }
    .back-to-home {
      width: 120px;
      height: 40px;
      // background: -webkit-linear-gradient(180deg, rgba(3, 129, 255, 0.8) 0%, rgba(3, 129, 255, 0.1) 100%);
      box-shadow: inset 0px 0px 40px 0px rgba(3, 129, 255, 0.8);
      border-radius: 30px;
      border: 1px solid #0381FF;
      color: #60D6FF;
      font-size: 16px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      position: absolute;
      top: 60px;
      left: 44px;
      z-index: 9;
    }
  }
  .data-main {
    position: absolute;
    top: 140px;
    width: 1920px;
    display: flex;
    justify-content: space-evenly;
    .box {
      background: rgba(0, 0, 0, 0.5);
      box-shadow: inset 0px 0px 40px 0px rgba(3, 129, 255, 0.8);
      border-radius: 8px;
      border: 1px solid rgba(3, 129, 255, 1);
      position: relative;
      &::before {
        content: "";
        width: 28px;
        height: 28px;
        background-image: url("../../../assets/platform_data/angle_tl@2x.png");
        background-size: 28px 28px;
        position: absolute;
        top: -4px;
        left: -4px;
      }
      &::after {
        content: "";
        width: 28px;
        height: 28px;
        background-image: url("../../../assets/platform_data/angle_rb@2x.png");
        background-size: 28px 28px;
        position: absolute;
        bottom: -4px;
        right: -4px;
      }
    }
    .left {
      width: 504px;
      height: 912px;
      // margin-left: 40px;
      &.filter {
        filter: blur(6px);
      }
      .title {
        font-weight: bold;
      }
      .seedling-box {
        padding: 14px 24px;
        .h {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .class-name {
            width: 80px;
            font-size: 24px;
            color: #fff;
            display: flex;
            align-items: center;
            .i {
              width: 24px;
              height: 24px;
              margin-right: 4px;
            }
          }
          .sm-font {
            font-size: 14px;
            color: #60d6ff;
          }
          .amount {
            font-family: Bebas;
            font-size: 24px;
            color: #60d6ff;
          }
        }
        .product-list {
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .item {
            width: 140px;
            height: 215px;
            margin-bottom: 30px;
            overflow: hidden;
            cursor: pointer;
            position: relative;
            .pic {
              width: 140px;
              height: 140px;
              border-radius: 3px 3px 0 0;
            }
            .number {
              width: 140px;
              height: 24px;
              background: rgba(0, 0, 0, 0.5);
              color: #fff;
              font-size: 14px;
              // text-align: center;
              padding-left: 8px;
              position: absolute;
              top: calc(140px - 24px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .sale {
              width: 140px;
              height: 75px;
              box-sizing: border-box;
              border-radius: 0px 0px 3px 3px;
              border-color: #2e6fee;
              border-right: .5px solid;
              border-bottom: .5px solid;
              border-left: .5px solid;
              color: #60d6ff;
              font-size: 12px;
              // text-align: center;
              padding: 4px;
              line-height: 24px;
              position: absolute;
              bottom: 0;
              .price-wrap {
                display: flex;
                align-items: center;
                height: 22px;
                .value {
                  color: #F7B500;
                  font-size: 16px;
                }
              }
            }
            &:not(:nth-child(3n)) {
              margin-right: 18px;
            }
          }
        }
      }
    }
    .right {
      //   margin-left: 40px;
      &.filter {
        filter: blur(6px);
      }
      .title {
        font-weight: bold;
      }
      .block1 {
        width: 504px;
        height: 216px;
        .data-card {
          padding: 0 24px 14px 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .item {
            width: 136px;
            height: 144px;
            border-radius: 4px;
            text-align: center;
            &.l,
            &.r {
              background: linear-gradient(270deg, #0575e6 0%, #00f260 100%);
            }
            &.c {
              background: linear-gradient(270deg, #2e6fee 0%, #0091ff 100%);
            }
            .i {
              width: 24px;
              height: 24px;
              margin: 12px 0 6px 0;
            }
            .data-name {
              font-size: 16px;
              color: #fff;
            }
            .data-unit {
              font-size: 12px;
              color: #fff;
              margin: 4px 0;
            }
            .data-value {
              font-family: Bebas;
              font-size: 40px;
              color: #fff;
            }
          }
        }
      }
      .block2 {
        width: 504px;
        // height: 673px;
        height: 282px;
        margin-top: 24px;
        .data-card {
          width: 456px;
          height: 168px;
          margin: 15px 24px;
          position: relative;
          .l {
            width: 168px;
            height: 168px;
            background: linear-gradient(270deg, #0575e6 0%, #00f260 100%);
            border-radius: 4px;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            .i {
              width: 24px;
              height: 24px;
              margin: 16px 0 12px 0;
            }
            .data-name {
              font-size: 16px;
              color: #fff;
            }
            .data-unit {
              font-size: 12px;
              color: #fff;
              margin: 8px 0;
            }
            .data-value {
              font-family: Bebas;
              font-size: 40px;
              color: #fff;
            }
          }
          .r-card {
            width: 264px;
            height: 168px;
            position: absolute;
            top: 0;
            right: 0;
            .item {
              width: 120px;
              height: 72px;
              background: rgba(46, 111, 238, 0.5);
              border-radius: 4px;
              border: 1px solid #60d6ff;
              text-align: center;
              position: absolute;
              .name {
                font-size: 16px;
                color: #fff;
                margin: 12px 0 2px 0;
              }
              .val {
                font-family: Bebas;
                font-size: 24px;
                color: #fff;
              }
            }
            .item1 {
              top: 0;
              left: 0;
            }
            .item2 {
              top: 0;
              right: 0;
            }
            .item3 {
              bottom: 0;
              left: 0;
            }
            .item4 {
              bottom: 0;
              right: 0;
            }
          }
        }
        .chart-box {
          height: 200px;
        }
      }
      .block3 {
        width: 504px;
        height: 374px;
        margin-top: 24px;
        font-size: 14px;
        .header {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 26px;
          color: #60D6FF;
          font-size: 14px;
          .select {
            ::v-deep .el-select .el-input .el-input__inner {
              border: none;
              background-color: transparent;
              color: #fff;
            }
            ::v-deep .custom-popper {
              background-color: rgba(0, 0, 0, .75);
              border: 1px solid #60d6ff;
              border-radius: 10px;
              .el-select-dropdown__item {
                margin: 0 24px;
                text-align: center;
                background-color: transparent;
                font-weight: 400;
                color: #f4f4f4;
                &:not(:last-child) {
                  border-bottom: 1px solid #a3c3eb;
                }
              }
              .el-select-dropdown__item.selected {
                color: #60D6FF;
              }
              .popper__arrow {
                visibility: hidden;
              }
            }
          }
        }
        .list {
          padding: 0 26px;
          .video {
            position: relative;
            min-width: 140px;
            min-height: 105px;
            margin-bottom: 20px;
            .title {
              position: absolute;
              bottom: 0;
              width: calc(100% - 16px);
              height: 17px;
              line-height: 17px;
              text-align: center;
              background: rgba(0, 0, 0, .5);
              font-weight: 400;
              color: #eee;
            }
          }
        }
        .pagination {
          position: absolute;
          right: 0;
          bottom: 10px;
          display: flex;
          justify-content: flex-end;
          padding: 0 26px;
          color: #60D6FF;
          .pages, .btn {
            min-width: 60px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            border: 1px solid #006C91;
            border-radius: 11px;
            font-size: 12px;
            &:not(:first-child) {
              margin-left: 5px;
            }
            &:not(:last-child) {
              margin-right: 5px;
            }
          }
          .btn {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    .center {
      width: 720px;
      height: 912px;
      // margin-left: 40px;
    }
    .left > .title,
    .right > .box > .title {
      font-size: 24px;
      color: #60d6ff;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background-image: url('../../../assets/cloud/underline_platform_title@2x.png');
      background-repeat: no-repeat;
      background-size: 75% 75%;
      background-position: 50% 80%;
    }
  }

  .slide-box {
    height: 160px;
    text-align: center;
    overflow: hidden;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
    .slide-list {
      width: 340px;
      text-align: center;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      position: absolute;
      top: 0;
      left: calc(56px / 2);
      margin: 0 51px;
    }
    .slide-card {
      width: 100%;
      height: 37px;
      line-height: 37px;
      // margin: 0 51px 0;
      // padding: 20px 24px;
      // background: linear-gradient(180deg, #ebf2f8 0%, #ffffff 100%);
      background: transparent;
      box-shadow: 8px 8px 24px 4px rgba(40, 60, 134, 0.08);
      border-bottom: 1px solid #2E6FEE;
      // border-radius: 8px;
      // border: 2px solid #fff;
      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
      font-size: 14px;
      color: #fff;
      .order {
        display: flex;
        justify-content: center;
        div {
          margin: 0 2px;
        }
        .orange {
          max-width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #ff9500;
        }
      }
    }
    
    .empty {
      position: absolute;
      left: 56px;
      top: calc(160px / 2 - 28px);
      width: calc(100% - 56px * 2);
      height: 28px;
      line-height: 28px;
      text-align: center;
      border-bottom: 1px solid #467af4;
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>