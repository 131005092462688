<template>
    <div ref="ecpieLG" style="width: 312px; height: 240px;"></div>
</template>

<script>
import * as echarts from 'echarts';
import { EC_Pie_LinearGradient } from '@/utils/ec.js'
export default {
    name: 'ModalPie',
    props: {
        title: {
            type: String,
            default: 'pie'
        },
        data: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            myChart: ''
        }
    },
    watch: {
        data() {
            let opt = EC_Pie_LinearGradient(this.data, this.title)
            this.myChart.setOption(opt)
        }
    },
    mounted() {
        let chartDom = this.$refs.ecpieLG
        this.myChart = echarts.init(chartDom);
    }
}
</script>