<template>
    <div class="ec-line">
        <div class="charts-title">
            <div class="tit">{{title}}</div>
            <div class="legend">{{item}}</div>
        </div>
        <div ref="ecline" style="width: 100%; height: 168px;"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import { EC_Line } from '@/utils/ec.js'
export default {
    name: 'RightLine',
    props: {
        title: {
            type: String,
            default: 'title'
        },
        item: {
            type: String,
            default: 'item'
        },
        data: {
            type: Array,
            default: []
        }
    },
    mounted() {
        let chartDom = this.$refs.ecline
        let opt = EC_Line(this.data, this.item)
        let myChart = echarts.init(chartDom);
        myChart.setOption(opt)
    }
}
</script>

<style lang="less" scoped>
.ec-line {
    .charts-title {
        padding: 0 23px;
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tit {
            width: 168px;
            height: 32px;
            background: rgba(46, 111, 238, .5);
            border-radius: 4px;
            // border: 1px solid #2E6FEE;
            line-height: 32px;
            text-align: center;
            color: #60D6FF;
            font-size: 16px;
        }
        .legend {
            color: #60D6FF;
            font-size: 12px;
            position: relative;
            &::before {
                content: '';
                width: 44px;
                height: 2px;
                background-color: #60D6FF;
                position: absolute;
                top: 8px;
                left: -50px;
            }
        }
    }
}
</style>