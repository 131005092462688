<template>
  <div class="platform" ref="platform">
    <el-image
      class="bg-style"
      src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/platform_bg_2x.jpg"
      fit="none"
    >
    </el-image>
    <div class="header-box">
      <el-image
        class="title-bg"
        src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/title_bg_1x.png"
        fit="cover"
      >
      </el-image>
      <!-- <div class="title">华南(中山)花木产业交易服务平台大数据中心</div> -->
      <div class="title">华南（中山）花木产业线上交易平台大数据中心</div>
      <div class="back-to-home" @click="backToHome">返回首页</div>
    </div>
    <div class="data-main">
      <div class="box left" :class="showModalData ? 'filter' : ''">
        <div class="title">认证会员苗木库存数据</div>
        <div
          class="seedling-box"
          v-for="(item, index) in leftDataFromApi"
          :key="index"
        >
          <div class="h">
            <div class="class-name">
              <el-avatar class="i" :src="item.img"></el-avatar>
              <div>{{ item.typeName }}</div>
            </div>
            <div class="sm-font">品类(种){{ item.classAmount }}</div>
            <div class="sm-font">库存数量(株)</div>
            <div class="amount">{{ item.stock }}</div>
          </div>
          <div class="product-list">
            <div
              class="item"
              v-for="(iitem, iindex) in item.goods"
              :key="iindex"
              @click="handleProduct(iitem)"
            >
              <el-image
                style="width: 96px; height: 96px"
                :src="iitem.img"
                fit="fit"
              ></el-image>
              <div class="number">
                NO.{{ iindex + 1 }} {{ iitem.goodsName }}
              </div>
              <div class="sale">{{ iitem.amount }}万</div>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <SummaryData :filterBox="showModalData"></SummaryData>
        <CenterMap :data="mapData" :filterBox="showModalData"></CenterMap>
      </div>
      <div class="right" :class="showModalData ? 'filter' : ''">
        <div class="box block1">
          <div class="title">交易平台数据概览</div>
          <div class="data-card">
            <div class="item l">
              <el-avatar class="i" :src="bigDataIMG.i_suppliers"></el-avatar>
              <div class="data-name">入驻供应商总数</div>
              <div class="data-unit">单位(家)</div>
              <div class="data-value">{{ rightData.supplierCount }}</div>
            </div>
            <div class="item c">
              <el-avatar class="i" :src="bigDataIMG.i_buyer"></el-avatar>
              <div class="data-name">入驻采购商总数</div>
              <div class="data-unit">单位(家)</div>
              <div class="data-value">{{ rightData.buyerCount }}</div>
            </div>
            <div class="item r">
              <el-avatar class="i" :src="bigDataIMG.i_driver"></el-avatar>
              <div class="data-name">入驻物流司机总数</div>
              <div class="data-unit">单位(人)</div>
              <div class="data-value">{{ rightData.driverCount }}</div>
            </div>
          </div>
        </div>
        <div class="box block2">
          <div class="title" style="border-bottom: 1px solid #0381ff">
            交易平台订单统计
          </div>
          <div class="data-card">
            <div class="l">
              <el-avatar class="i" :src="bigDataIMG.i_order"></el-avatar>
              <div class="data-name">累计交易金额</div>
              <div class="data-unit">单位（万元）</div>
              <div class="data-value">
                {{ rightData.totalTransactionAmount }}
              </div>
            </div>
            <div class="r-card">
              <div class="item item1">
                <div class="name">待确认订单</div>
                <div class="val">{{ rightData.toBeConfirmedOrderCount }}</div>
              </div>
              <div class="item item2">
                <div class="name">待支付订单</div>
                <div class="val">{{ rightData.toBePayOrderCount }}</div>
              </div>
              <div class="item item3">
                <div class="name">待发货订单</div>
                <div class="val">{{ rightData.toBeShipOrderCount }}</div>
              </div>
              <div class="item item4">
                <div class="name">已收货订单</div>
                <div class="val">{{ rightData.receivedOrderCount }}</div>
              </div>
            </div>
          </div>
          <div class="chart-box">
            <RightLine
              :data="rightLineData"
              title="预期半年交易金额趋势"
              item="交易金额趋势"
            ></RightLine>
          </div>
          <div class="chart-box" style="margin-top: 10px">
            <RightBar
              :data="rightBarData"
              title="预期半年订单总量趋势"
              item="订单趋势"
            ></RightBar>
          </div>
        </div>
      </div>
    </div>
    <ModalData
      ref="modal"
      :show="showModalData"
      :seedlingNumber="handleNumberVal"
      :info="productInfo"
      @closeModal="handleCloseModal"
    ></ModalData>
  </div>
</template>

<script>
import SummaryData from "./SummaryData.vue";
import CenterMap from "./CenterMap.vue";
import ModalData from "./ModalData.vue";
import imgSrc from "@/utils/bigDataIMG.js";
const bigDataIMG = imgSrc.data();
import { dotNumber, detectZoom } from "@/utils";

import RightLine from "./RightLine.vue";
import RightBar from "./RightBar.vue";
const rightLineData = [
  { value: 100000, name: "9月" },
  { value: 300000, name: "10月" },
  { value: 1000000, name: "11月" },
  { value: 2000000, name: "12月" },
  { value: 3000000, name: "1月" },
  { value: 5000000, name: "2月" },
];
const rightBarData = [
  { value: 4, name: "9月" },
  { value: 12, name: "10月" },
  { value: 40, name: "11月" },
  { value: 80, name: "12月" },
  { value: 120, name: "1月" },
  { value: 200, name: "2月" },
]
import {
  findKindOfNumAndTotalInventoryByType,
  findTopInventoryMostSeedlingByType,
  findSouthernChinaWarehouseNum, //地图
  showShopStatistics,
} from "@/api";
export default {
  name: "PlatformIndex",
  components: {
    SummaryData,
    CenterMap,
    ModalData,
    RightLine,
    RightBar,
  },
  data() {
    return {
      bigDataIMG,
      leftDataFromApi: [],
      handleNumberVal: "",
      rightLineData,
      rightBarData,
      showModalData: false,
      productInfo: {},
      mapData: [],
      rightData: {},
    };
  },
  watch: {
    showModalData(val) {
      if (val) {
        // this.$refs.platform.style.transformOrigin = 'unset'
        this.$refs.platform.style.transform = 'none'
      } else {
        this.$refs.platform.style.transform = `scale(${detectZoom()})`
      }
    }
  },
  mounted() {
    this.$refs.platform.style.transformOrigin = '0 0'
    this.$refs.platform.style.transform = `scale(${detectZoom()})`
    document.body.style.backgroundColor = '#00142E'

    var concatLeftData = {};
    var leftDataFromApi = [];
    // 23:乔木,25:灌木,26:地被,88:盆栽
    findKindOfNumAndTotalInventoryByType({ type: 23 })
      .then((res) => {
        concatLeftData = {};
        (concatLeftData.type = "23"),
          (concatLeftData.img = bigDataIMG.i_tree),
          (concatLeftData.typeName = "乔木"),
          (concatLeftData.classAmount = res.data.kindOfNum);
        concatLeftData.stock = dotNumber(res.data.totalInventory);
        return findTopInventoryMostSeedlingByType({ type: 23 });
      })
      .then((res) => {
        concatLeftData.goods = res.data.map((goods) => {
          return {
            goodsFullName: goods.seedlingName,
            goodsName: goods.seedlingName.length > 3 ? goods.seedlingName.substring(0, 3)+'..' : goods.seedlingName,
            amount: dotNumber(goods.totalInventory/10000),
            img: goods.mainImage,
            seedlingNumber: goods.seedlingNumber,
            commonName: goods.commonName,
          };
        });
        leftDataFromApi.push(concatLeftData);
        return findKindOfNumAndTotalInventoryByType({ type: 25 });
      })
      .then((res) => {
        concatLeftData = {};
        (concatLeftData.type = "25"),
          (concatLeftData.img = bigDataIMG.i_shrub),
          (concatLeftData.typeName = "灌木"),
          (concatLeftData.classAmount = res.data.kindOfNum);
        concatLeftData.stock = dotNumber(res.data.totalInventory);
        return findTopInventoryMostSeedlingByType({ type: 25 });
      })
      .then((res) => {
        concatLeftData.goods = res.data.map((goods) => {
          return {
            goodsFullName: goods.seedlingName,
            goodsName: goods.seedlingName.length > 3 ? goods.seedlingName.substring(0, 3)+'..' : goods.seedlingName,
            amount: dotNumber(goods.totalInventory/10000),
            img: goods.mainImage,
            seedlingNumber: goods.seedlingNumber,
            commonName: goods.commonName,
          };
        });
        leftDataFromApi.push(concatLeftData);
        return findKindOfNumAndTotalInventoryByType({ type: 26 });
      })
      .then((res) => {
        concatLeftData = {};
        (concatLeftData.type = "26"),
          (concatLeftData.img = bigDataIMG.i_lawn),
          (concatLeftData.typeName = "地被"),
          (concatLeftData.classAmount = res.data.kindOfNum);
        concatLeftData.stock = dotNumber(res.data.totalInventory);
        return findTopInventoryMostSeedlingByType({ type: 26 });
      })
      .then((res) => {
        concatLeftData.goods = res.data.map((goods) => {
          return {
            goodsFullName: goods.seedlingName,
            goodsName: goods.seedlingName.length > 3 ? goods.seedlingName.substring(0, 3)+'..' : goods.seedlingName,
            amount: dotNumber(goods.totalInventory/10000),
            img: goods.mainImage,
            seedlingNumber: goods.seedlingNumber,
            commonName: goods.commonName,
          };
        });
        leftDataFromApi.push(concatLeftData);
        return findKindOfNumAndTotalInventoryByType({ type: 88 });
      })
      .then((res) => {
        concatLeftData = {};
        (concatLeftData.type = "88"),
          (concatLeftData.img = bigDataIMG.i_potting),
          (concatLeftData.typeName = "盆栽"),
          (concatLeftData.classAmount = res.data.kindOfNum);
        concatLeftData.stock = dotNumber(res.data.totalInventory);
        return findTopInventoryMostSeedlingByType({ type: 88 });
      })
      .then((res) => {
        concatLeftData.goods = res.data.map((goods) => {
          return {
            goodsFullName: goods.seedlingName,
            goodsName: goods.seedlingName.length > 3 ? goods.seedlingName.substring(0, 3)+'..' : goods.seedlingName,
            amount: dotNumber(goods.totalInventory/10000),
            img: goods.mainImage,
            seedlingNumber: goods.seedlingNumber,
            commonName: goods.commonName,
          };
        });
        leftDataFromApi.push(concatLeftData);
        this.leftDataFromApi = leftDataFromApi;
        return findSouthernChinaWarehouseNum();
      })
      .then((res) => {
        this.mapData = res.data.map((item) => {
          return {
            name: item.cityName,
            value: item.warehouseNum,
          };
        });
        return showShopStatistics();
      })
      .then((res) => {
        this.rightData = res.data;
        this.rightData.totalTransactionAmount = dotNumber(res.data.totalTransactionAmount/10000)
      });
  },
  destroyed() {
    // this.$refs.platform.style.transformOrigin = 'unset'
    // this.$refs.platform.style.transform = 'none'
    document.body.style.backgroundColor = '#fff'
  },
  methods: {
    handleProduct(item) {
      this.handleNumberVal = item.seedlingNumber;
      this.productInfo = item;
      this.$nextTick(() => {
        this.$refs.modal.initPieData()
        this.showModalData = true;
      })
    },
    handleCloseModal() {
      this.showModalData = false;
    },
    backToHome() {
      // document.querySelector('html').style.transform = `scale(1)`
      // document.body.style.backgroundColor = '#fff'
      // this.$router.go(-1)
      this.$router.replace({ name: 'Home' })
    }
  },
};
</script>

<style lang="less" scoped>
.platform {
  height: 100%;
  user-select: none;
  // overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  .bg-style {
    width: 1920px;
    height: 1080px;
    position: absolute;
    top: 0;
    // right: 0;
    // bottom: 0;
    left: 0;
  }
  .header-box {
    width: 1900px;
    height: 232px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    .title-bg {
      width: 100%;
      height: auto;
      z-index: 1;
    }
    .title {
      font-size: 36px;
      font-family: PangMenZhengDao;
      color: #60d6ff;
      letter-spacing: 2px;
      //   text-shadow: 0px 4px 8px #001183;
      background: -webkit-linear-gradient(180deg, #60d6ff 0%, #5369ff 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      position: absolute;
      top: 44px;
      width: 100%;
      text-align: center;
    }
    .back-to-home {
      width: 120px;
      height: 40px;
      // background: -webkit-linear-gradient(180deg, rgba(3, 129, 255, 0.8) 0%, rgba(3, 129, 255, 0.1) 100%);
      box-shadow: inset 0px 0px 40px 0px rgba(3, 129, 255, 0.8);
      border-radius: 30px;
      border: 1px solid #0381FF;
      color: #60D6FF;
      font-size: 16px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      position: absolute;
      top: 60px;
      left: 44px;
      z-index: 9;
    }
  }
  .data-main {
    position: absolute;
    top: 140px;
    width: 1920px;
    display: flex;
    justify-content: space-evenly;
    .box {
      background: rgba(0, 0, 0, 0.5);
      box-shadow: inset 0px 0px 40px 0px rgba(3, 129, 255, 0.8);
      border-radius: 8px;
      border: 1px solid rgba(3, 129, 255, 1);
      position: relative;
      &::before {
        content: "";
        width: 28px;
        height: 28px;
        background-image: url("../../assets/platform_data/angle_tl@2x.png");
        background-size: 28px 28px;
        position: absolute;
        top: -4px;
        left: -4px;
      }
      &::after {
        content: "";
        width: 28px;
        height: 28px;
        background-image: url("../../assets/platform_data/angle_rb@2x.png");
        background-size: 28px 28px;
        position: absolute;
        bottom: -4px;
        right: -4px;
      }
    }
    .left {
      width: 504px;
      height: 912px;
      // margin-left: 40px;
      &.filter {
        filter: blur(6px);
      }
      .title {
        font-weight: bold;
      }
      .seedling-box {
        padding: 14px 24px;
        .h {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .class-name {
            width: 80px;
            font-size: 24px;
            color: #fff;
            display: flex;
            align-items: center;
            .i {
              width: 24px;
              height: 24px;
              margin-right: 4px;
            }
          }
          .sm-font {
            font-size: 14px;
            color: #60d6ff;
          }
          .amount {
            font-family: Bebas;
            font-size: 24px;
            color: #60d6ff;
          }
        }
        .product-list {
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .item {
            width: 96px;
            height: 121px;
            overflow: hidden;
            cursor: pointer;
            position: relative;
            .number {
              width: 96px;
              height: 24px;
              background: rgba(0, 0, 0, 0.5);
              color: #fff;
              font-size: 14px;
              text-align: center;
              position: absolute;
              bottom: 25px;
              overflow: hidden;
            }
            .sale {
              width: 94px;
              height: 24px;
              border-radius: 0px 0px 3px 3px;
              border-color: #2e6fee;
              border-right: .5px solid;
              border-bottom: .5px solid;
              border-left: .5px solid;
              color: #60d6ff;
              font-size: 12px;
              text-align: center;
              line-height: 24px;
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }
    .right {
      //   margin-left: 40px;
      &.filter {
        filter: blur(6px);
      }
      .title {
        font-weight: bold;
      }
      .block1 {
        width: 504px;
        height: 216px;
        .data-card {
          padding: 0 24px 14px 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .item {
            width: 136px;
            height: 144px;
            border-radius: 4px;
            text-align: center;
            &.l,
            &.r {
              background: linear-gradient(270deg, #0575e6 0%, #00f260 100%);
            }
            &.c {
              background: linear-gradient(270deg, #2e6fee 0%, #0091ff 100%);
            }
            .i {
              width: 24px;
              height: 24px;
              margin: 12px 0 6px 0;
            }
            .data-name {
              font-size: 16px;
              color: #fff;
            }
            .data-unit {
              font-size: 12px;
              color: #fff;
              margin: 4px 0;
            }
            .data-value {
              font-family: Bebas;
              font-size: 40px;
              color: #fff;
            }
          }
        }
      }
      .block2 {
        width: 504px;
        height: 673px;
        margin-top: 24px;
        .data-card {
          width: 456px;
          height: 168px;
          margin: 15px 24px;
          position: relative;
          .l {
            width: 168px;
            height: 168px;
            background: linear-gradient(270deg, #0575e6 0%, #00f260 100%);
            border-radius: 4px;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            .i {
              width: 24px;
              height: 24px;
              margin: 16px 0 12px 0;
            }
            .data-name {
              font-size: 16px;
              color: #fff;
            }
            .data-unit {
              font-size: 12px;
              color: #fff;
              margin: 8px 0;
            }
            .data-value {
              font-family: Bebas;
              font-size: 40px;
              color: #fff;
            }
          }
          .r-card {
            width: 264px;
            height: 168px;
            position: absolute;
            top: 0;
            right: 0;
            .item {
              width: 120px;
              height: 72px;
              background: rgba(46, 111, 238, 0.5);
              border-radius: 4px;
              border: 1px solid #60d6ff;
              text-align: center;
              position: absolute;
              .name {
                font-size: 16px;
                color: #fff;
                margin: 12px 0 2px 0;
              }
              .val {
                font-family: Bebas;
                font-size: 24px;
                color: #fff;
              }
            }
            .item1 {
              top: 0;
              left: 0;
            }
            .item2 {
              top: 0;
              right: 0;
            }
            .item3 {
              bottom: 0;
              left: 0;
            }
            .item4 {
              bottom: 0;
              right: 0;
            }
          }
        }
        .chart-box {
          height: 200px;
        }
      }
    }
    .center {
      width: 720px;
      height: 912px;
      // margin-left: 40px;
    }
    .left > .title,
    .right > .box > .title {
      font-size: 24px;
      color: #60d6ff;
      height: 48px;
      line-height: 48px;
      text-align: center;
    }
  }
}
</style>