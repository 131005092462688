<template>
  <div class="modal-mask" v-show="show">
    <div class="modal-container">
      <img :src="closeIcon" alt="close" @click="handleClose" />
      <div class="row-info">
        <div class="title">{{info.goodsFullName}}</div>
        <div class="keysword">常用名：{{info.commonName}}</div>
      </div>
      <div class="row-info">
        <div class="inv">总库存：{{info.amount}}万</div>
      </div>
      <div class="pie-list" v-if="show">
        <div class="item">
          <div class="ec-tit">规格数据分布图</div>
          <div class="ec-sub-tit">单位(株)</div>
          <ModalPie title="规格数据分布图" :data="pieData"></ModalPie>
        </div>
        <div class="item">
          <div class="ec-tit">供应苗圃地区分布</div>
          <div class="ec-sub-tit">单位(家)</div>
          <ModalPie title="规格数据分布图" :data="pieData2"></ModalPie>
        </div>
        <div class="item">
          <div class="ec-tit">采购浏览量地区分布</div>
          <div class="ec-sub-tit">单位(万次)</div>
          <ModalPie title="规格数据分布图" :data="pieData3"></ModalPie>
        </div>
        <div class="item">
          <div class="ec-tit">采购电话量地区分布</div>
          <div class="ec-sub-tit">单位(万次)</div>
          <ModalPie title="规格数据分布图" :data="pieData4"></ModalPie>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalPie from "./ModalPie.vue";
import closeIcon from "@/assets/platform_data/icon-close@2x.png";
// const pieData = [
//   { value: 1048, name: "胸径0-5cm" },
//   { value: 735, name: "胸径5-10cm" },
//   { value: 580, name: "胸径10-15cm" },
//   { value: 484, name: "胸径15-20cm" },
//   { value: 300, name: "胸径20cm以上" },
// ];
// const pieData2 = [
//   { value: 1048, name: "广东中山" },
//   { value: 735, name: "广东揭阳" },
//   { value: 580, name: "广东广州" },
//   { value: 484, name: "广东佛山" },
//   { value: 300, name: "其他城市" },
// ];
// const pieData3 = [
//   { value: 1048, name: "中山市" },
//   { value: 735, name: "佛山市" },
//   { value: 580, name: "广州市" },
//   { value: 484, name: "揭阳市" },
//   { value: 300, name: "其他" },
// ];
// const pieData4 = [
//   { value: 1048, name: "广东中山" },
//   { value: 735, name: "广东揭阳" },
//   { value: 580, name: "广东广州" },
//   { value: 484, name: "广东佛山" },
//   { value: 300, name: "其他城市" },
// ];
import {
  findSeedlingStandardInventory,
  findTopWarehouseMostAreaBySeedlingNumber,
  findTopViewNumMostAreaBySeedlingNumber,
  findTopCallNumMostAreaBySeedlingNumber
} from "@/api";

export default {
  name: "ModalData",
  components: {
    ModalPie,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    seedlingNumber: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      closeIcon,
      pieData: [],
      pieData2: [],
      pieData3: [],
      pieData4: [],
    };
  },
  methods: {
    handleClose() {
      this.$emit("closeModal", false);
    },
    initPieData() {
      // 规格分布
      findSeedlingStandardInventory({seedlingNumber: this.seedlingNumber}).then(res=>{
        this.pieData = res.data.map(item=>{
          return {
            name: item.standardName+item.standardRange,
            value: item.inventory
          }
        })
        // 供应分布
        return findTopWarehouseMostAreaBySeedlingNumber({seedlingNumber: this.seedlingNumber})
      }).then(res=>{
        this.pieData2 = res.data.map(item=>{
          return {
            name: item.areaName,
            value: item.warehouseNum
          }
        })
        // 浏览量分布
        // return true
        return findTopViewNumMostAreaBySeedlingNumber({seedlingNumber: this.seedlingNumber})
      }).then(res=>{
        this.pieData3 = res.data.map(item=>{
          return {
            name: item.areaName,
            value: item.viewNum
          }
        })
        // 电话量分布
        return findTopCallNumMostAreaBySeedlingNumber({seedlingNumber: this.seedlingNumber})
      }).then(res=>{
        this.pieData4 = res.data.map(item=>{
          return {
            name: item.areaName,
            value: item.callNum
          }
        })
        // this.showModalData = true;
      })
    }
  },
};
</script>

<style lang="less" scoped>
.modal-mask {
  background: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  .modal-container {
    width: 696px;
    height: 792px;
    background: rgba(1, 20, 141, 0.3);
    box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.2),
      0px 0px 48px 0px rgba(3, 129, 255, 0.4);
    border-radius: 10px;
    border: 1px solid #60d6ff;
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & > img {
      width: 24px;
      height: auto;
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
    .row-info {
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .title {
        font-size: 32px;
        color: #60d6ff;
        padding-right: 20px;
      }
      .keysword {
        font-size: 14px;
        color: #60d6ff;
      }
      .inv {
        font-size: 20px;
        color: #60d6ff;
      }
    }
    .pie-list {
      margin-top: 32px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      .item {
        width: 312px;
        height: 312px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        border: 1px solid #60d6ff;
        margin-bottom: 24px;
        text-align: center;
        .ec-tit {
          color: #60d6ff;
          font-size: 24px;
          margin-top: 15px;
        }
        .ec-sub-tit {
          color: #eee;
          font-size: 14.5px;
          padding: 4px 0;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .modal-mask > .modal-container {
    height: 710px;
    margin: 0 auto;
    .row-info {
      margin-bottom: 6px;
      .title {
        font-size: 26px;
      }
      .keysword {
        font-size: 13px;
      }
      .inv {
        font-size: 16px;
      }
    }
    .pie-list {
      margin-top: 16px;
      .item {
        height: 300px;
        .ec-tit {
          font-size: 18px;
          margin-top: 8px;
        }
        .ec-sub-tit {
          font-size: 13px;
          padding: 4px 0;
        }
      }
    }
  }
}
</style>