import * as echarts from 'echarts';
import fujian from './fujian.json'
import guangxi from './guangxi.json'
import guangdong from './guangdong.json'
import hainan from './hainan.json'

var fmtCity = (data) => {
    return data.features.map(item => {
        return {
            city: item.properties.name,
            name: item.properties.name.substring(0, item.properties.name.length - 1),
            value: item.properties.center,
            centroid: item.properties.centroid
        }
    })
}

export function EC_Pie(data, title) {
    const demoData = [
        { value: 1048, name: '中山' },
        { value: 735, name: '佛山' },
        { value: 580, name: '广州' },
        { value: 484, name: '揭阳' },
        { value: 300, name: '其他' }
    ]
    let mergeData = data.length > 0 ? data : demoData
    return {
        tooltip: {
            trigger: 'item',
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            formatter: function (item) {
                return '<div style="background:rgba(0, 0, 0, 0.7);border-radius:4px;padding:12px;border: 1px solid #2E6FEE;box-shadow:0 0 10px rgba(255, 255, 255, 0.2)">' +
                    '<div style="color:#fff;font-size:16px;">' + title + '</div>' +
                    '<div style="color:#fff;font-size:14px;margin:4px 0 0;">'+ item.name + ':' + item.value + '</div></div>';
            }
        },
        color: ['#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16', '#E8684A'],
        series: [
            {
                name: title,
                type: 'pie',
                radius: ['18%', '50%'],
                // avoidLabelOverlap: false,
                label: {
                    // show: false,
                    // position: 'center'
                    formatter: '{b}\n{d}%',
                    textStyle: {
                        color: '#fff',
                        textShadowColor: 'transparent',
                        // width: 48,
                        // height: 40,
                    },
                },
                labelLine: {
                    //   show: false
                    length: 6,
                    length2: 5
                },
                data: mergeData
            }
        ]
    };
}
export function EC_Pie_LinearGradient(data, title) {
    const demoData = [
        { value: 1048, name: '中山' },
        { value: 735, name: '佛山' },
        { value: 580, name: '广州' },
        { value: 484, name: '揭阳' },
        { value: 300, name: '其他' }
    ]
    let mergeData = data.length > 0 ? data : demoData
    return {
        tooltip: {
            trigger: 'item',
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            formatter: function (item) {
                return '<div style="background:rgba(0, 0, 0, 0.7);border-radius:4px;padding:12px;border: 1px solid #2E6FEE;box-shadow:0 0 10px rgba(255, 255, 255, 0.2)">' +
                    '<div style="color:#fff;font-size:16px;">' + title + '</div>' +
                    '<div style="color:#fff;font-size:14px;margin:4px 0 0;">'+ item.name + ':' + item.value + '</div></div>';
            }
        },
        series: [
            {
                name: title,
                type: 'pie',
                radius: ['18%', '50%'],
                // avoidLabelOverlap: false,
                label: {
                    // show: false,
                    // position: 'center'
                    formatter: '{b}\n{d}%',
                    textStyle: {
                        color: '#fff',
                        textShadowColor: 'transparent',
                        // width: 48,
                        // height: 40,
                    },
                },
                itemStyle: {
                    color: params => {
                        let colorList = [
                            { c1: '#0575E6', c2: '#00F260' },
                            { c1: '#378FFF', c2: '#0005F8' },
                            { c1: '#5D7092', c2: '#5D7092' },
                            { c1: '#F6BD16', c2: '#F6BD16' },
                            { c1: '#F5A181', c2: '#E8684A' }
                        ];
                        return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            { offset: 0, color: colorList[params.dataIndex].c1 },
                            { offset: 1, color: colorList[params.dataIndex].c2 }
                        ]);
                    },
                },
                labelLine: {
                    //   show: false
                    length: 6,
                    length2: 5
                },
                data: mergeData
            }
        ]
    };
}

export function EC_Line(data, itemName) {
    const demoData = [
        { value: 120, name: "2021-03" },
        { value: 132, name: "2021-04" },
        { value: 101, name: "2021-05" },
        { value: 134, name: "2021-06" },
        { value: 90, name: "2021-07" },
        { value: 230, name: "2021-08" },
        { value: 210, name: "2021-09" }
    ];
    let mergeData = data.length > 0 ? data : demoData
    return {
        color: ['#60D6FF'],
        tooltip: {
            trigger: 'axis',
            // axisPointer: {
            //     type: 'cross',
            //     label: {
            //         backgroundColor: '#6a7985'
            //     }
            // },
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            formatter: function (item) {
                return '<div style="background:rgba(0, 0, 0, 0.7);border-radius:4px;padding:12px;border: 1px solid #2E6FEE;box-shadow:0 0 10px rgba(255, 255, 255, 0.2)">' +
                    '<div style="color:#fff;font-size:16px;">' + item[0].name + '</div>' +
                    '<div style="color:#fff;font-size:14px;margin:4px 0 0;">交易金额：' + item[0].value + '</div></div>';
            }
        },
        grid: {
            top: '7%',
            left: '5%',
            right: '5%',
            bottom: '1%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                // boundaryGap: false,
                axisLine: {
                    lineStyle: {
                        color: '#2E6FEE'
                    }
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    textStyle: {
                        color: '#60D6FF'
                    }
                },
                data: mergeData.map(item => item.name)
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    lineStyle: {
                        color: '#2E6FEE'
                    }
                },
                axisLabel: {
                    textStyle: {
                        color: '#60D6FF'
                    }
                },
            }
        ],
        series: [
            {
                name: itemName ? itemName : 'line1',
                type: 'line',
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(96, 214, 255, 1)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(96, 214, 255, 0)'
                        }
                    ])
                },
                emphasis: {
                    focus: 'series'
                },
                data: mergeData.map(item => item.value)
            }
        ]
    };
}

export function EC_Bar(data, itemName) {
    const demoData = [
        { value: 120, name: "2021-03" },
        { value: 132, name: "2021-04" },
        { value: 101, name: "2021-05" },
        { value: 134, name: "2021-06" },
        { value: 90, name: "2021-07" },
        { value: 230, name: "2021-08" },
        { value: 210, name: "2021-09" }
    ];
    let mergeData = data.length > 0 ? data : demoData
    return {
        tooltip: {
            trigger: 'axis',
            // axisPointer: {
            //     type: 'cross',
            //     label: {
            //         backgroundColor: '#6a7985'
            //     }
            // },
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            formatter: function (item) {
                return '<div style="background:rgba(0, 0, 0, 0.7);border-radius:4px;padding:12px;border: 1px solid #2E6FEE;box-shadow:0 0 10px rgba(255, 255, 255, 0.2)">' +
                    '<div style="color:#fff;font-size:16px;">' + item[0].name + '</div>' +
                    '<div style="color:#fff;font-size:14px;margin:4px 0 0;">订单总量：' + item[0].value + '</div></div>';
            }
        },
        grid: {
            top: '7%',
            left: '5%',
            right: '5%',
            bottom: '1%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            axisLine: {
                lineStyle: {
                    color: '#2E6FEE'
                }
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                textStyle: {
                    color: '#60D6FF'
                }
            },
            data: mergeData.map(item => item.name)
        },
        yAxis: {
            type: 'value',
            splitLine: {
                lineStyle: {
                    color: '#2E6FEE'
                }
            },
            axisLabel: {
                textStyle: {
                    color: '#60D6FF'
                }
            }
        },
        series: [
            {
                name: itemName ? itemName : 'bar1',
                data: mergeData.map(item => item.value),
                type: 'bar',
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#00F260' },
                        { offset: 1, color: '#0575E6' }
                    ])
                }
            }
        ]
    };
}

export function EC_Map(data) {
    // 三省的所有城市
    var groupCity = fmtCity(fujian).concat(fmtCity(guangxi)).concat(fmtCity(guangdong)).concat(fmtCity(hainan))
    var moveLines = []
    let showCity = []
    if (data && data.length > 0) {
        data.forEach(item => {
            let cityCenter = ''
            let coords = []
            groupCity.forEach(city => {
                if (city.name == item.name) {
                    cityCenter = city.value
                    showCity.push({
                        name: item.name,
                        number: item.value,
                        value: cityCenter
                    })
                    coords = [[113.392782, 22.517646], city.value]
                    moveLines.push({
                        fromName: '中山',
                        toName: item.name,
                        coords: coords,
                    })
                }
            });
        })
    }
    // console.log(moveLines)
    return {
        tooltip: {
            trigger: 'item',
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            formatter: function (item) {
                // console.log(item, 'item!!!!')
                if (item.componentSubType === 'effectScatter') {
                    return '<div style="background:rgba(0, 0, 0, 0.7);border-radius:4px;padding:12px;border: 1px solid #2E6FEE;box-shadow:0 0 10px rgba(255, 255, 255, 0.2)">' +
                        '<div style="color:#fff;font-size:16px;">' + item.data.name + '</div>' +
                        '<div style="color:#fff;font-size:14px;margin:4px 0 0;">苗圃数：' + item.data.number + '个</div></div>';
                }
            }
        },
        legend: {
            show: false,
            orient: 'vertical',
            top: 'bottom',
            left: 'right',
            data: ['地点', '线路'],
            textStyle: {
                color: '#fff'
            }
        },
        geo: {
            map: 'china',
            tooltip: {
                // show: false,
                textStyle: {
                    color: '#60D6FF'
                },
            },
            label: {
                emphasis: {
                    show: false,
                }
            },
            center: [112.55, 23.16],
            zoom: 6,
            roam: false,
            itemStyle: {
                normal: {
                    areaColor: new echarts.graphic.RadialGradient(0.5, 0.5, 0.9, [{
                        offset: 0,
                        color: 'rgba(0, 0, 0, .2)'
                    }, {
                        offset: 1,
                        color: 'rgba(3, 129, 255, 1)'
                    }]),
                    borderColor: '#60D6FF',
                    borderWidth: 1,
                },
                emphasis: {
                    areaColor: 'transparent'
                }
            },
            regions: [
                {
                    name: '海南省',
                    itemStyle: {
                        normal: {
                            areaColor: new echarts.graphic.RadialGradient(0.1, 0.1, 0.3, [{
                                offset: 0,
                                color: 'rgba(0, 0, 0, .2)'
                            }, {
                                offset: 1,
                                color: 'rgba(3, 129, 255, 1)'
                            }])
                        }
                    }
                }
            ],
        },
        series: [
            {
                name: '线路',
                type: 'lines',
                coordinateSystem: 'geo',
                zlevel: 2,
                large: true,
                // effect: {
                //     show: true,
                //     constantSpeed: 30,
                //     symbol: 'pin',
                //     symbolSize: 3,
                //     trailLength: 0,
                // },
                lineStyle: {
                    normal: {
                        color: '#60D6FF',
                        width: 1,
                        opacity: .59,
                        curveness: 0.1
                    }
                },
                data: moveLines
            },
            {
                name: '地点',
                type: 'effectScatter',
                coordinateSystem: 'geo',
                zlevel: 5,
                rippleEffect: {  //涟漪特效
                    brushType: 'stroke',//波纹绘制方式 stroke，fill
                    period: 4,//动画时间，值越小速度越快
                    scale: 4,//波纹圆环最大显示，值越大波纹越大
                },
                label: {
                    formatter: '{b}',
                    fontSize: 12,
                    distance: 10,
                    position: 'top',
                    show: true,
                    textStyle: {
                        color: '#60D6FF',
                        textShadowColor: '#000',
                        // width: 48,
                        // height: 40,
                    },
                    // 鼠标hover
                    // emphasis: {
                    //     label: {
                    //         show: true
                    //     },
                    //     // formatter: '{b}\n',
                    // }
                },
                symbolSize: 2,
                showEffectOn: 'render',
                itemStyle: {
                    normal: {
                        color: '#FF6666'
                    }
                },
                data: showCity
            },
            // {
            //     type: 'scatter',
            //     coordinateSystem: 'geo',
            //     zlevel: 2,
            //     rippleEffect: {
            //         period: 4,
            //         brushType: 'stroke',
            //         scale: 4
            //     },
            //     label: {
            //         normal: {
            //             show: true,
            //             position: 'right',
            //             //offset:[5, 0],
            //             color: '#0f0',
            //             formatter: '{b}',
            //             textStyle: {
            //                 color: "#0f0",
            //                 fontSize: 30
            //             }
            //         },
            //         emphasis: {
            //             show: true,
            //             color: "#f60"
            //         }
            //     },
            //     symbol: 'pin',
            //     symbolSize: 80,
            //     data: [{
            //         name: '中山',
            //         value: [113.392782, 22.517646],
            //     }],
            // }
        ]
    }
}