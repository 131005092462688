<template>
  <div class="ec-bar">
    <div class="charts-title">
      <div class="tit">{{ title }}</div>
      <div class="legend">
        <div class="item-block"></div>
        <div class="item-name">{{ item }}</div>
      </div>
    </div>
    <div ref="ecbar" style="width: 100%; height: 168px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { EC_Bar } from "@/utils/ec.js";
export default {
  name: "RightBar",
  props: {
    title: {
      type: String,
      default: "title",
    },
    item: {
      type: String,
      default: "item",
    },
    data: {
      type: Array,
      default: [],
    },
  },
  mounted() {
    let chartDom = this.$refs.ecbar;
    let opt = EC_Bar(this.data, this.item);
    let myChart = echarts.init(chartDom);
    myChart.setOption(opt);
  },
};
</script>

<style lang="less" scoped>
.ec-bar {
  .charts-title {
    padding: 0 23px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tit {
      width: 168px;
      height: 32px;
      background: rgba(46, 111, 238, 0.5);
      border-radius: 4px;
      // border: 1px solid #2e6fee;
      line-height: 32px;
      text-align: center;
      color: #60d6ff;
      font-size: 16px;
    }
    .legend {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item-block {
        width: 16px;
        height: 16px;
        background: linear-gradient(360deg, #0575e6 0%, #00f260 100%);
        margin-right: 8px;
      }
      .item-name {
        color: #60d6ff;
        font-size: 12px;
      }
    }
  }
}
</style>