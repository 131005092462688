<template>
  <div class="ec-map" :class="filterBox ? 'filter' : ''">
    <img
      class="bg-circle"
      src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/map_ circle.png"
      alt="circle"
    />
    <div ref="ecmap" style="width: 720px; height: 502px"></div>
    <img
      class="bg-map-s"
      src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/map_china_2x.png"
      alt="circle"
    />
    <div class="location">
      <img :src="bigDataIMG.icon_location" alt="icon_location" />
      <div class="name">中山</div>
    </div>
  </div>
</template>

<script>
import imgSrc from "@/utils/bigDataIMG.js";
const bigDataIMG = imgSrc.data();
import * as echarts from "echarts";
import china from "@/utils/china.js";
import { EC_Map } from "@/utils/ec.js";
export default {
  name: "CenterMap",
  props: {
    data: {
      type: Array,
      default: [],
    },
    filterBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myMap: "",
      bigDataIMG,
    };
  },
  watch: {
    data(val) {
      let opt = EC_Map(val);
      this.myMap.setOption(opt);
    },
  },
  mounted() {
    let chartDom = this.$refs.ecmap;
    this.myMap = echarts.init(chartDom);
  },
};
</script>

<style lang="less" scoped>
.ec-map {
  width: 720px;
  height: 502px;
  position: relative;
  &.filter {
    filter: blur(6px);
  }
  .bg-circle {
    width: 720px;
    height: auto;
    position: absolute;
    bottom: 54px;
  }
  .bg-map-s {
    width: 170px;
    height: 170px;
    position: absolute;
    right: 0;
    bottom: 24px;
  }
  .location {
      width: 100px;
      text-align: center;
      position: absolute;
      top: 230px;
      left: 340px;
      img {
          width: 22px;
          height: 44px;
      }
      .name {
          font-size: 36px;
          color: #60D6FF;
          text-shadow: 5px 5px 10px#000;
      }
  }
}
</style>