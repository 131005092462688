<template>
    <div ref="ecpie" style="width: 240px; height: 205px;"></div>
</template>

<script>
import * as echarts from 'echarts';
import { EC_Pie } from '@/utils/ec.js'
export default {
    name: 'CenterPie',
    props: {
        title: {
            type: String,
            default: 'pie'
        },
        data: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            myChart: ''
        }
    },
    watch: {
        data() {
            let opt = EC_Pie(this.data, this.title)
            this.myChart.setOption(opt)
        }
    },
    mounted() {
        let chartDom = this.$refs.ecpie
        this.myChart = echarts.init(chartDom);
    }
}
</script>