<template>
  <div class="summary-data" :class="filterBox ? 'filter' : ''">
    <el-image
      class="title-bg"
      style="width: 335px; height: 118px"
      :src="bigDataIMG.middle_title_bg"
      fit="fit"
    ></el-image>
    <div class="title">数据汇总</div>
    <div class="screen-box">
      <div class="item">
        <div class="name">苗圃总数</div>
        <div class="unit">单位(个)</div>
        <div class="screen">
          <countTo :startVal="startVal" :endVal="warehouseNum" separator="" :duration="1000"></countTo>
          <!-- <div>{{ warehouseNum }}</div> -->
          <img class="tl" :src="bigDataIMG.i_triangle" alt="i_triangle" />
          <img class="tr" :src="bigDataIMG.i_triangle" alt="i_triangle" />
          <img class="bl" :src="bigDataIMG.i_triangle" alt="i_triangle" />
          <img class="br" :src="bigDataIMG.i_triangle" alt="i_triangle" />
        </div>
      </div>
      <div class="item">
        <div class="name">苗木品种</div>
        <div class="unit">单位(类)</div>
        <div class="screen">
          <countTo :startVal="startVal" :endVal="seedlingNum" separator="" :duration="1000"></countTo>
          <!-- <div>{{ seedlingNum }}</div> -->
          <img class="tl" :src="bigDataIMG.i_triangle" alt="i_triangle" />
          <img class="tr" :src="bigDataIMG.i_triangle" alt="i_triangle" />
          <img class="bl" :src="bigDataIMG.i_triangle" alt="i_triangle" />
          <img class="br" :src="bigDataIMG.i_triangle" alt="i_triangle" />
        </div>
      </div>
      <div class="item">
        <div class="name">库存总量</div>
        <div class="unit">单位(亿株)</div>
        <div class="screen">
          <countTo :startVal="startVal" :endVal="invNum" separator="" :decimals="2" :duration="1000"></countTo>
          <!-- <div>{{ invNum }}</div> -->
          <img class="tl" :src="bigDataIMG.i_triangle" alt="i_triangle" />
          <img class="tr" :src="bigDataIMG.i_triangle" alt="i_triangle" />
          <img class="bl" :src="bigDataIMG.i_triangle" alt="i_triangle" />
          <img class="br" :src="bigDataIMG.i_triangle" alt="i_triangle" />
        </div>
      </div>
    </div>
    <div class="charts-box">
      <div class="flex-box">
        <div class="item">
          <CenterPie title="苗圃总数" :data="pieData"></CenterPie>
        </div>
        <div class="item">
          <CenterPie title="苗木品种" :data="pieData2"></CenterPie>
        </div>
        <div class="item">
          <CenterPie title="库存总量" :data="pieData3"></CenterPie>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
import imgSrc from "@/utils/bigDataIMG.js";
const bigDataIMG = imgSrc.data();
import CenterPie from "./CenterPie.vue";

import {
  findTotalWarehouseNum,
  findTotalSeedlingNum,
  findTotalInventory,
  findTopWarehouseMostArea,
  findTopSeedlingNumMostType,
  findTopInventoryMostCity,
} from "@/api";

export default {
  name: "SummaryData",
  props: {
    filterBox: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    countTo,
    CenterPie,
  },
  data() {
    return {
      startVal: 0,
      bigDataIMG,
      warehouseNum: 0,
      seedlingNum: 0,
      invNum: 0,
      pieData: [],
      pieData2: [],
      pieData3: [],
    };
  },
  mounted() {
    findTotalWarehouseNum()
      .then((res) => {
        // 苗圃总数
        this.warehouseNum = res.data;
        return findTotalSeedlingNum();
      })
      .then((res) => {
        // 品种
        this.seedlingNum = res.data;
        return findTotalInventory();
      })
      .then((res) => {
        // 库存总量
        this.invNum = res.data / 100000000;
        return findTopWarehouseMostArea();
      })
      .then((res) => {
        // 饼1
        this.pieData = res.data.map((item) => {
          return {
            name: item.areaName,
            value: item.warehouseNum,
          };
        });
        return findTopSeedlingNumMostType();
      })
      .then((res) => {
        // 饼2
        this.pieData2 = res.data.map((item) => {
          return {
            name: item.typeName,
            value: item.seedlingNum,
          };
        });
        return findTopInventoryMostCity();
      })
      .then((res) => {
        // 饼3
        this.pieData3 = res.data.map((item) => {
          return {
            name: item.areaName,
            value: item.totalInventory,
          };
        });
      });
  },
};
</script>

<style lang="less" scoped>
.summary-data {
  width: 720px;
  height: 412px;
  background: rgba(1, 3, 60, 0.5);
  box-shadow: inset 0px 0px 56px 0px rgba(3, 129, 255, 0.8);
  border-radius: 8px;
  border: 1px solid rgba(3, 129, 255, 1);
  position: relative;
  &.filter {
    filter: blur(6px);
  }
  .title-bg {
    position: absolute;
    top: -23px;
    left: 200px;
  }
  & > .title {
    font-size: 24px;
    color: #60d6ff;
    font-weight: bold;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
  }
  .screen-box {
    position: relative;
    top: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .item {
      text-align: center;
      .name {
        font-size: 20px;
        color: #60d6ff;
      }
      .unit {
        font-size: 12px;
        color: #eee;
        margin: 4px 0;
      }
      .screen {
        width: 208px;
        height: 96px;
        // background: #071a5e;
        background: radial-gradient(
          rgba(96, 214, 255, 0.55) 0%,
          rgba(96, 214, 255, 0.3) 30%,
          rgba(7, 26, 94, 0.9) 80%
        );
        border: 1px solid rgba(83, 105, 255, 0.5);
        margin-top: 12px;
        font-family: DigitalNumbers;
        font-size: 36px;
        color: #fff;
        font-weight: bold;
        line-height: 96px;
        position: relative;
        .tl {
          width: 24px;
          height: 24px;
          position: absolute;
          top: -6px;
          left: -8px;
          transform: rotate(180deg);
        }
        .tr {
          width: 24px;
          height: 24px;
          position: absolute;
          top: -8px;
          right: -6px;
          transform: rotate(-90deg);
        }
        .bl {
          width: 24px;
          height: 24px;
          position: absolute;
          bottom: -8px;
          left: -6px;
          transform: rotate(90deg);
        }
        .br {
          width: 24px;
          height: 24px;
          position: absolute;
          bottom: -6px;
          right: -8px;
        }
      }
    }
  }
  .charts-box {
    width: 100%;
    height: 205px;
    position: absolute;
    bottom: 0;
    .flex-box {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .item {
        width: 240px;
        height: 205px;
      }
    }
  }
}
</style>