<template>
  <div class="hello-ezuikit-js">
    <div :id="`video-container${index !== 0 ? `-${index}` : ''}`" :style="styleObj" />
    <!-- <div>
      <button v-on:click="stop">stop</button>
      <button v-on:click="play">play</button>
      <button v-on:click="openSound">openSound</button>
      <button v-on:click="closeSound">closeSound</button>
      <button v-on:click="startSave">startSave</button>
      <button v-on:click="stopSave">stopSave</button>
      <button v-on:click="capturePicture">capturePicture</button>
      <button v-on:click="fullScreen">fullScreen</button>
      <button v-on:click="getOSDTime">getOSDTime</button>
      <button v-on:click="ezopenStartTalk">开始对讲</button>
      <button v-on:click="ezopenStopTalk">结束对讲</button>
      <button v-on:click="destroy">销毁</button>
    </div> -->
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
// var player = null;
export default {
  name: "EZUIKitJs",
  props: {
    index: {
      type: Number,
      default: 0
    },
    accessToken: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    // 容器宽度
    width: {
      type: Number,
      default: 140
    },
    // 容器高度
    height: {
      type: Number,
      default: 105
    },
    // 是否开启声音
    audio: {
      type: Boolean,
      default: true
    },
    // 是否开启高清
    hd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // player实例
      player: null
    }
  },
  computed: {
    // EZUIKitJs容器的行内样式对象
    styleObj() {
      const { width, height } = this
      return {
        'width': `${width}px`,
        'height': `${height}px`
      }
    }
  },
  mounted() {
    const { 
      index,
      url,
      accessToken,
      width,
      height,
      audio,
      hd
    } = this
    console.group("mounted 组件挂载完毕状态===============》");
    const player = new EZUIKit.EZUIKitPlayer({
      id: `video-container${index !== 0 ? `-${index}` : ''}`, // 视频容器ID
      accessToken,
      url,
      // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
      //template: 'simple',
      // plugin: ['talk'], // 加载插件，talk-对讲
      width,
      height,
      audio
    })
    this.player = player
    // 是否开启高清
    hd && player.changePlayUrl({ hd })
    // window.player = player;
  },
  destroyed() {
    // 销毁player示例
    this.destroy()
  },
  methods: {
    play() {
      var playPromise = this.player.play();
      playPromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    stop() {
      var stopPromise = this.player.stop();
      stopPromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    getOSDTime() {
      var getOSDTimePromise = this.player.getOSDTime();
      getOSDTimePromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    capturePicture() {
      var capturePicturePromise = this.player.capturePicture(`${new Date().getTime()}`);
      capturePicturePromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    openSound() {
            var openSoundPromise = this.player.openSound();
      openSoundPromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    closeSound() {
      var openSoundPromise = this.player.closeSound();
      openSoundPromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    startSave() {
            var startSavePromise = this.player.startSave(`${new Date().getTime()}`);
      startSavePromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    stopSave() {
      var stopSavePromise = this.player.stopSave();
      stopSavePromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    ezopenStartTalk() {
      this.player.startTalk();
    },
    ezopenStopTalk() {
      this.player.stopTalk();
    },
    fullScreen() {
      this.player.fullScreen();
    },
    destroy() {
      if(this.player !== null) {
        var destroyPromise = this.player.destroy();
        destroyPromise.then((data) => {
          this.player = null
          console.log("promise 获取 数据", data)
        })
      }
    }
  }
};
</script>
